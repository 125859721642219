import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TreeSelectorComponent } from '../components/tree-selector/tree-selector.component';
import { ArcDateTimeRangePickerComponent } from '../components/arc-date-time-range-picker/arc-date-time-range-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TimePickerComponent } from '../components/time-picker/time-picker.component';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppDateTimeRangePickerComponent } from '../components/app-date-time-range-picker/app-date-time-range-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    TreeSelectorComponent,
    ArcDateTimeRangePickerComponent,
    AppDateTimeRangePickerComponent,
    TimePickerComponent
  ],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    CommonModule,
    MatCheckboxModule,

    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    TreeSelectorComponent,
    TimePickerComponent,
    ArcDateTimeRangePickerComponent,
    AppDateTimeRangePickerComponent
  ],
  providers: [

  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        
      ],
    };
  }
}
