import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private logoutSubject = new Subject<void>();
  private logoutSubscription: Subscription;

  constructor(private msalService: MsalService, private _sessionStorageService: SessionStorageService) {
    // Check session expiration periodically
    this.logoutSubscription = this.monitorSessionExpiry().subscribe(() => {
      this.autoLogout();
    });
  }

  ngOnDestroy() {
    // Clean up subscriptions
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  private monitorSessionExpiry(): Observable<void> {
    return new Observable<void>(observer => {
      const checkInterval = 60000; // Check every minute
      const intervalId = setInterval(() => {
        const account = this.msalService.instance.getAllAccounts()[0];
        if (account && this.isTokenExpired(account)) {
          observer.next();
        }
      }, checkInterval);

      // Clean up the interval on unsubscribe
      return () => clearInterval(intervalId);
    });
  }

  private isTokenExpired(account: any): boolean {
    const expiry = new Date(account.idTokenClaims.exp * 1000);
    return expiry < new Date();
  }

  private autoLogout() {
    this._sessionStorageService.clear();
    this.msalService.instance.logoutRedirect(); // Log out and redirect to the home page
  }
}
