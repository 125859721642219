import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private storage = sessionStorage;

  constructor() { }

  public setSession(skey: string, svalue: string) {
    this.storage.setItem(skey, JSON.stringify(svalue));
  }
  public getSession(skey: string): any {
    return JSON.parse(this.storage.getItem(skey));
  }

  public removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}
