// role.service.ts
import { Injectable } from '@angular/core';
import { UserRole } from '../directives/roles';
import { RolePermissions } from '../directives/permissions';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private _sessionStorageService:SessionStorageService){}
  
  hasPermission(permission: string): boolean {
    let currentuser = this._sessionStorageService.getSession('user_data');
    if(currentuser !== null){
      const role = currentuser['RoleNames'][0] as UserRole ;
      return RolePermissions[role][permission];
    }
    
    return false;
  }
}

