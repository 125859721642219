import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  constructor(private msalService: MsalService) { }

  ngOnInit(): void {
   
  }

  logOut(){
    this.msalService.instance.logoutRedirect();
  }
}
