import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../app/environments/environment';

const httpHeadersValue = {
  headers: new HttpHeaders({

  })
}
httpHeadersValue.headers = new HttpHeaders().append('Content-Type', 'application/json');
@Injectable({
  providedIn: 'root'
})
export class UtilitySvc {
  currentURL = '';
  browseripAddress: string = '';

  public ipAddress: string = '';
  public VMipAddress: string = '';
  // public ipAddress1: string = '';
  public ipAddressUsermanag: string = '';
  public getDeviceSummaryURL: string = ''; 
  public getConsumptionPattern: string = '';
  public GetDeviceDetailsByFleet: string = '';
  public heirarchyMenu: string = '';
  public ComaprisonheirarchyMenu: string = '';
  public getGISGoogleMap:string = '';

  public getCumulativeWaterConsuption: string = '';
  public getDeviceDetails: string  = '';
  public getDeviceDetailsForVM: string = '';
  public getDeviceFlowrateGraph: string = '';
  public getGraphDataByDeviceId:string = '';
  public getGraphDataByDeviceIdforVM:string = '';
  public getDeviceTotalizerGraph: string = '';
  public getDeviceTotatlizerDonutChart:string = '';
  public getDeviceTotalizerCount: string = '';
  public getDeviceTotalizerCountForVM: string = '';
  public getDeviceTotatlizerDonutChartForVM: string = '';

  public getCriticalParameters: string = '';
  public getCriticalParametersHistory: string ='';
  public getHealthReport: string = '';
  public getHealthTrends: string = '';
  public getDiagnosticsCondition: string = '';
  public getConfigurations: string = '';
  public postSaveToDevice:string  = '';
  public getNightViewMultiData: string = '';
  public getNightViewCount: string = '';

  public getNightViewMultiDataForVM: string = '';
  public getNightViewCountForVM: string = '';

  public getEventDeviceLogs: string = '';
  public getEventGenericLogs: string = '';

  public getTagComparisonGroup: string = '';
  public getTagComparisonTagsMenu: string = '';
  public getTagComparisonGroupChartData: string = '';
  public addTagComparisonGroup: string = '';
  public editTagComparisonGroup: string = '';
  public deleteTagComparisonGroup: string = '';
  public getTenantInfo:string = '';
  public getUserToken: string = '';  
  public getLicenseInfo : string = '';

  constructor(private _httpService: HttpClient) {
    this.currentURL = window.location.href;
    this.ipAddress = environment.GatewayURL;
    this.VMipAddress = this.ipAddress;

    this.getCumulativeWaterConsuption = this.VMipAddress + "/api/AquaMasterGateway/GetWaterflowConsumption";
    this.getDeviceSummaryURL = this.VMipAddress + "/api/AquaMasterGateway/GetDeviceSummary"; 
    this.getConsumptionPattern = this.VMipAddress + "/api/AquaMasterGateway/GetConsumptionPattern";
    this.GetDeviceDetailsByFleet = this.VMipAddress + "/api/AquaMasterGateway/GetDeviceDetailsByFleet";
    this.heirarchyMenu = this.VMipAddress + "/api/AquaMasterGateway/GetMenu";
    this.ComaprisonheirarchyMenu = this.VMipAddress + "/api/AquaMasterGateway/GetComparisonMenu";
    this.getGISGoogleMap = this.VMipAddress + "/api/AquaMasterGateway/GetDeviceListWithState";
    this.getUserToken = this.VMipAddress+ "/api/AquaMasterGateway/Get";


    // Device Insight

    this.getDeviceDetails = this.VMipAddress + "/api/AquaMasterGateway/GetParameterDetailsByDeviceId";
    this.getDeviceDetailsForVM = this.VMipAddress + "/api/AquaMasterGateway/GetParameterDetailsByDeviceIdForVM";
    // this.getDeviceFlowrateGraph = this.VMipAddress + "/api/AquaMasterGateway/GetFlowratesByDeviceId";

    this.getGraphDataByDeviceId = this.VMipAddress + '/api/AquaMasterGateway/GetGraphDataByDeviceId';
    this.getGraphDataByDeviceIdforVM = this.VMipAddress + '/api/AquaMasterGateway/GetGraphDataByDeviceIdForVM';
    
    this.getDeviceTotalizerGraph = this.VMipAddress + "/api/AquaMasterGateway/GetTotalizerHistory";
    this.getDeviceTotalizerCount = this.VMipAddress + "/api/AquaMasterGateway/GetTotalizerDetailsByDeviceId";
    this.getDeviceTotatlizerDonutChart = this.VMipAddress + "/api/AquaMasterGateway/GetTotalizerDonutCount";

    this.getDeviceTotalizerCountForVM = this.VMipAddress + "/api/AquaMasterGateway/GetTotalizerDetailsByDeviceIdForVM";
    this.getDeviceTotatlizerDonutChartForVM = this.VMipAddress + "/api/AquaMasterGateway/GetTotalizerDonutCountForVM";

    this.getCriticalParameters = this.VMipAddress + "/api/AquaMasterGateway/GetCriticalParameters";
    this.getCriticalParametersHistory = this.VMipAddress + "/api/AquaMasterGateway/GetCriticalParametersHistory";
    this.getHealthReport = this.VMipAddress + "/api/AquaMasterGateway/GetHealthReport";
    this.getHealthTrends = this.VMipAddress + "/api/AquaMasterGateway/GetHealthTrends";
    this.getDiagnosticsCondition = this.VMipAddress + "/api/AquaMasterGateway/GetDiagnosticsConditions";

    this.getConfigurations = this.VMipAddress + "/api/AquaMasterGateway/GetConfigurationParameters";
    this.postSaveToDevice = this.VMipAddress + "/api/AquaMasterGateway/SaveConfigurationParameters"

    // Night View
    this.getNightViewMultiData = this.VMipAddress + "/api/AquaMasterGateway/GetNightAnalyzerDetails";
    this.getNightViewCount = this.VMipAddress + "/api/AquaMasterGateway/GetNightFlowDetailsByDeviceId";


    this.getNightViewMultiDataForVM = this.VMipAddress + "/api/AquaMasterGateway/GetNightAnalyzerDetailsForVM";
    this.getNightViewCountForVM = this.VMipAddress + "/api/AquaMasterGateway/GetNightFlowDetailsByDeviceIdForVM";
    //event Log

    this.getEventDeviceLogs = this.VMipAddress + "/api/AquaMasterGateway/GetEventLogDetails";
    this.getEventGenericLogs = this.VMipAddress + "/api/AquaMasterGateway/GetGenericLogDetails";

    //Tag Comparison APi
    this.getTagComparisonGroup = this.VMipAddress + "/api/AquaMasterGateway/GetComparisonGroup";
    this.getTagComparisonGroupChartData = this.VMipAddress + "/api/AquaMasterGateway/GetComparisonGroupData";
    this.getTagComparisonTagsMenu = this.VMipAddress + "/api/AquaMasterGateway/GetComparisonMenu";

    this.addTagComparisonGroup = this.VMipAddress + "/api/AquaMasterGateway/CreateComparisonGroup";
    this.editTagComparisonGroup = this.VMipAddress + "/api/AquaMasterGateway/UpdateComparisonGroup";
    this.deleteTagComparisonGroup = this.VMipAddress + "/api/AquaMasterGateway/DeleteComparisonGroup";

    this.getLicenseInfo = this.VMipAddress + "/api/AquaMasterGateway/GetLicenseInfo";
    this.getTenantInfo = this.VMipAddress + "/api/AquaMasterGateway/GetTenantInfo";
       
  }
}
