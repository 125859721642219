import { UserRole } from "./roles";

  // permissions.ts
  export const RolePermissions = {
    [UserRole.SolutionAdmin]: {
      canEditUsers: true,
      canDeleteUsers: true,
      canManageRoles: true,
      canCustomerMenuVisible: false,
      canUserMenuVisible: true,
    },
    [UserRole.Admin]: {
      canEditUsers: false,
      canDeleteUsers: false,
      canManageRoles: false,
      canCustomerMenuVisible: true,
      canUserMenuVisible: true,
    },
    [UserRole.Supervisor]: {
      canEditUsers: false,
      canDeleteUsers: false,
      canManageRoles: false,
      canCustomerMenuVisible: false,
      canUserMenuVisible: false,
    },
    [UserRole.Operator]: {
      canEditUsers: false,
      canDeleteUsers: false,
      canManageRoles: false,
      canCustomerMenuVisible: false,
      canUserMenuVisible: false,
    },
  };
  