import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from '../../components/toaster/toaster.component';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    ToasterComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  providers:[
    { provide: MAT_SNACK_BAR_DATA, useValue: {}}
  ],
  exports: [
    ToasterComponent
  ]
})
export class ToasterModule { }
