import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { LandingComponent } from './modules/landing/landing.component';
import { UserRole } from './directives/roles';
import { RoleGuard } from './role.guard';
import { TokenErrorComponent } from './components/error/token-error.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
const routes: Routes = [
  {
    path: 'page-one',
    loadChildren: () =>
      import('../app/modules/page-one/page-one.module').then(
        (m) => m.PageOneModule
      ),
    pathMatch: 'full',
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
  {
    path: 'page-two',
    loadChildren: () =>
      import('../app/modules/page-two/page-two.module').then(
        (m) => m.PageTwoModule
      ),
      canActivate: [MsalGuard, RoleGuard],
      data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
  {
    path: 'area-overview',
    loadChildren: () =>
      import('../app/modules/area-overview/area-overview.module').then(
        (m) => m.AreaOverviewModule
      ),
      canActivate: [MsalGuard, RoleGuard],
      data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
  {
    path: 'device-insight',
    loadChildren: () =>
      import('../app/modules/device-insight/device-insight.module').then(
        (m) => m.DeviceInsightModule
      ),
      canActivate: [MsalGuard, RoleGuard],
      data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
  {
    path: 'event-log',
    loadChildren: () =>
      import('./modules/event-log/event-log.module').then(
        (m) => m.EventLogModule
      ),
      canActivate: [MsalGuard, RoleGuard],
      data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
  { path: 'landing',
    component: LandingComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
   {
    path: 'tag-comparision',
    loadChildren: () =>
      import('./modules/tag-compare/tag-compare.module').then(
        (m) => m.TagCompareModule
      ),
      canActivate: [MsalGuard, RoleGuard],
      data: { expectedRole: [UserRole.Supervisor, UserRole.Operator]}
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    // Needed for handling redirect after login
    path: 'error',
    component: TokenErrorComponent
  },
  {
    // Needed for handling redirect after login
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  // { path: 'unauthorized', component: UnauthorizedComponent },
  // { path: '**', redirectTo: 'unauthorized' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
