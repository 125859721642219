import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../components/toaster/toaster.component';

export interface Toast {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  display: false;
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private snackBar: MatSnackBar) { }

  show(message: string, action: string) {
    this.snackBar.openFromComponent(ToasterComponent, { data: { message: message, type: action, display: true}, verticalPosition: "top", horizontalPosition:"right"});
  }
}
