import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  visibility: boolean = false;
  variantSpinner: number = 70;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    setInterval(() => { 
        if(this.variantSpinner < 100){
        this.variantSpinner = this.variantSpinner + 5;
        }
    }, 500);
    
    this.subscription = this.loaderService.loader$.subscribe((visibility: boolean) => {
      this.visibility = visibility;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
