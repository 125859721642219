// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RoleService } from './services/role.service';
import { UserRole } from './directives/roles';
import { SessionStorageService } from './services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private roleService: RoleService, private _sessionStorageService:SessionStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const expectedRoles = next.data['expectedRole'] as UserRole[];
    const isNoError= this._sessionStorageService.getSession('IsTokenGenerated') == 'true' ? true : false;
    if(!isNoError){
      this.router.navigate(['/error']);
      return false;
    }

    var currentUser = this._sessionStorageService.getSession('user_data');
    if( currentUser != null){
      if (expectedRoles.indexOf(currentUser['RoleNames'][0] as UserRole) < 0) {
        // Redirect to unauthorized page or another appropriate action
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }
    
    return true;
  }
}
