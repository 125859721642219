import { Component, Inject, OnInit } from '@angular/core';
import { ToasterService, Toast } from '../../services/toaster.service';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-toaster',
  templateUrl: 'toaster.component.html',
  styleUrls: ['toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  toasts: Toast[] = [];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Toast) { }

  ngOnInit(): void {
    this.toasts.push(this.data);
    setTimeout(() => this.removeToast(this.data), 7000);
  }

  removeToast(toast: Toast): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
