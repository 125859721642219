<div *ngIf="loginDisplay">
<section class="landing-ui-container">
    <div fxLayout="row" fxLayoutAlign="start start" class="ht-100">
      <div fxFlex="43" class="left-container">
        <div fxLayout="column" fxLayoutAlign="start start" class="row-section">
        <span class="red-border-top"></span>
        <div class="header-title row-section">{{title}}
        </div>
          <p> {{ description }}</p>
          <p> {{ icondescription }}</p> 
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <img src="assets/images/abb_aquamaster4.svg" alt="Infographic Image" class="info-img" />
        </div>
      </div>
      <div fxFlex="57" class="right-container">    
          <div fxLayout="row" fxLayoutAlign="start start" class="card-view">
            <div class="card-box-main" *ngFor="let menu of menus;">
              <!-- <div class="section-title">{{menu.title}}</div> -->
              <div class="sub-sections" fxLayout="row" fxLayoutAlign="start start">
                <div class="card-box" (click)="navigateToOverview(subMenu.url)"
                  *ngFor="let subMenu of menu.subMenus">
                  <mat-card appMaterialElevation class="animated-mat-card">
                    <mat-card-content>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="img"> <img [src]="subMenu.tileIcon" [alt]="subMenu.tileTitle" />
                        </div>
                        <div class="title-txt">{{ subMenu.tileTitle }}</div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>   
          <div class="footer-row1">
            <span >© </span> {{ date | date:'yyyy' }} Aquamaster+ | v1.0.8
        </div>
      </div>
    </div>
  </section>
</div>