  <!-- <mat-toolbar class="mat-headertoolbar"  [ngClass]="{'dark-theme': isDarkTheme, 'light-theme': !isDarkTheme}">
    <mat-toolbar-row class="mat-headertoolbar-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px"
      class="wd-100">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="logo-container">
        <div class="logo-img">
          <img src="../assets/images/ABB_logo.svg">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" class="ht-100 align-txt">
          <div class="red-border-toplt"></div>
          <div class="font-16-md">Ability™</div>
        </div>
        <div class="divider-line"></div>
        <div fxLayout="column"  fxLayoutAlign="center start" class="ht-100">
          <div class="font-16-md">Aquamaster4</div>
          <div class="breadcrumb-primary" *ngIf="!showLogin && !showlanding ">
            <ul>
              <li>
                <a> <span class="icon-abb_home icon_abb_dias"></span></a>
                <span class="icon-abb_right_16 icon_abb_16 arrow"></span>
              </li>
              <li>
                <a>{{routerStateName | uppercase}} </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="profile-icon"   [matMenuTriggerFor]="menu" *ngIf="loginDisplay">
        <span class="initial-txt">{{ shortName }}</span>
        <mat-menu #menu="matMenu" class="user-menu" [ngClass]="{'dark-theme': isDarkTheme, 'light-theme': !isDarkTheme}">
          <div class="user-info" (click)="$event.stopPropagation();"  [ngClass]="{'dark-theme': isDarkTheme, 'light-theme': !isDarkTheme}">
            <div id="dvUserInfo" class="profile-init">
              {{ shortName }}
            </div>
            <div  id="dvName" class="name">{{ name }}</div>
            <div id="dvMenuEmail" class="email">
              <span  mattooltipclass="tooltip" mattooltipposition="above" class="email-text"  [matTooltip]="emailVal">{{ userName }}</span>
            </div>
            <div class="list-section">
              <div class="item pad-1">
                <span class="icon-abb_user-preferance icon-size-16 icon_abb_dias"></span> <span> {{ userRole }}</span>
              </div>
              <div  id="btnTheme" class="item pad-8"><span class="icon_abb_dias icon-abb_theme icon-size-16"></span>
                <span>Theme</span>
                <mat-button-toggle-group class="toggle-button-variation-3 light-grey small"
                #groIcon32="matButtonToggleGroup" value="light" (change)="onValChange($event)">
                <mat-button-toggle value="light"><span class="icon_abb_dias icon-abb_light icon-size-16"></span><span>Light</span>
                </mat-button-toggle>
                <mat-button-toggle value="dark"><span class="icon_abb_dias icon-abb_dark icon-size-16"></span><span>Dark</span>
                  </mat-button-toggle>
              </mat-button-toggle-group>
                </div>
                <div id="btnLogOut" class="item pad-1"  *ngIf="loginDisplay" (click)="logout()"><span class="icon-abb_logout icon_abb_dias icon-size-16"></span><span>Log Out</span></div>
            </div>
            </div>   
      </mat-menu>  
      </div>
  
    </mat-toolbar-row>
    </mat-toolbar>
  -->
  <mat-toolbar class="mat-headertoolbar white-bg" fxLayout="row">
    <div class="first-part" fxLayout="row" fxLayoutAlign="start center">
      <!-- Hamburger -->
  
        <div *ngIf="!showLogin && !showlanding && IsValidUser" (click)="expandCollapse('sidebar1')"  matTooltipPosition="after"
          [matTooltip]="sidebarStates['sidebar1'] ? 'Close Menu' : 'Open Menu'">
          <div id="nav-icon" [ngClass]="sidebarStates['sidebar1'] ? 'open' : ''">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
  
      <img id="ABBPic" class="ABB-logo ml-5" src="../assets/images/ABB_logo.svg" />
      <div class="header-text" fxLayout="column" fxLayoutAlign="start start">
        <!-- <div class="red-top-line"></div>
        <div class="brand-name">OnboardPro</div> -->
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxlayout="column">
        <!-- <div class="red-top-line"></div> -->
        <div class="app-name">Aquamaster+ </div>
        <div class="breadcrumb-container" *ngIf="!showLogin && !showlanding && IsValidUser ">
          <div class="breadcrumb-primary">
            <ul class="breadcrumb-list">
              <li>
                <a (click)="returnToLanding()">
                  <span class="icon_abb_dias icon-abb_home breadcrumb-icon"></span>
                  <span>Home</span>
                </a>
                <span class="icon_abb_dias icon-abb_arrow-right_small-10 arrow"></span>
              </li>
              <li>
                <a>
                  <span>{{ routerStateName.split('-').join(' ') | titlecase }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
    <div class="avatar second-part" fxLayout="row" fxLayoutAlign="start center">
      <div class="search-container">
        <mat-form-field [class.active]="searchBarVisible[2]" floatLabel="always"
          class="form-field-default form-field discreet-field header-search-bar">
          <mat-label class="field-label" hidden>Label</mat-label>
          <input matInput class="input-field" autocomplete="off" value="" placeholder="Search"
            [(ngModel)]="searchQueries[2]" />
          <div *ngIf="searchQueries[2]" (click)="clearSearch(2)" class="cross-icon">
            <div class="icon_abb_dias icon-abb_cancel-1 pointer-hover"></div>
          </div>
        </mat-form-field>
        <!-- <span class="icon_abb_dias icon-abb_search1 icon_abb_24 header-icon search-icon"
          [class.active]="searchBarVisible[1]" (click)="toggleSearch(2)" #tooltip="matTooltip"
          matTooltip="Search" matTooltipPosition="below" matTooltipClass="tooltip"></span> -->
      </div>
      <!-- <span class="icon_abb_dias icon-abb_question-circle icon_abb_24 header-icon" #tooltip="matTooltip"
        matTooltip="Help" matTooltipPosition="below" matTooltipClass="tooltip"></span> -->
      <span class="icon-abb_alarm-bell_32 icon_abb_dias notification-bell-icon header-icon"
        #tooltip="matTooltip" matTooltip="Notifications" matTooltipPosition="below"
        matTooltipClass="tooltip"></span>
  
  
  
  
      <div class="focus-border avatar-gap" tabindex="1" [matMenuTriggerFor]="menu" *ngIf="loginDisplay">
        <div class="default base-style size-48">{{shortName}}</div>
        <mat-menu #menu="matMenu" class="user-menu" [ngClass]="{'dark-theme': isDarkTheme, 'light-theme': !isDarkTheme}">
          <div class="user-info" (click)="$event.stopPropagation();"  [ngClass]="{'dark-theme': isDarkTheme, 'light-theme': !isDarkTheme}">
            <div id="dvUserInfo" class="profile-init">
              {{ shortName }}
            </div>
            <div  id="dvName" class="name">{{ name }}</div>
            <div id="dvMenuEmail" class="email">
              <span  mattooltipclass="tooltip" mattooltipposition="above" class="email-text"  [matTooltip]="emailVal">{{ userName }}</span>
            </div>
            <div class="list-section">
              <div class="item pad-1">
                <span class="icon-abb_user-preferance icon-size-16 icon_abb_dias"></span><span>{{userRole}}</span>
                <!-- <span class="icon-abb_user-preferance icon-size-16 icon_abb_dias"></span><span>Manage Profile Settings</span> -->
              </div>
              <div  id="btnTheme" class="item pad-8"><span class="icon_abb_dias icon-abb_theme icon-size-16"></span>
                <span>Theme</span>
                <mat-button-toggle-group class="toggle-button-variation-3 light-grey small"
                #groIcon32="matButtonToggleGroup" value="light" (change)="onValChange($event)">
                <mat-button-toggle value="light"><span class="icon_abb_dias icon-abb_light icon-size-16"></span><span>Light</span>
                </mat-button-toggle>
                <mat-button-toggle value="dark"><span class="icon_abb_dias icon-abb_dark icon-size-16"></span><span>Dark</span>
                  </mat-button-toggle>
              </mat-button-toggle-group>
                </div>
                <div id="btnLogOut" class="item pad-1"  *ngIf="loginDisplay" (click)="logout()"><span class="icon-abb_logout icon_abb_dias icon-size-16"></span><span>Log Out</span></div>
            </div>
            </div>   
      </mat-menu> 
      </div>
    </div>
  </mat-toolbar>
 <div class="page" [ngClass]="{'dark-theme': isDarkTheme, 'light-theme': !isDarkTheme}">
  
  <div class="app-body" fxFlex="100%" fxLayout="row" *ngIf="!showLogin && loginDisplay">
    <!-- <div [ngClass]="{'sidebar-min': !expandSideNav,  'sidebar-full': expandSideNav }" *ngIf="showTemplate">
    <app-side-nav (expandSideNav)="onexpandSideNav($event)" *ngIf="!showlanding">
    </app-side-nav>
  </div> -->
  <div [ngClass]="{'sidebar-min': !sidebarStates['sidebar1'], 'sidebar-full': sidebarStates['sidebar1']}" *ngIf="!showlanding">
    <app-side-nav [sidebarStates]="sidebarStates" [sidebarId]="'sidebar1'" *ngIf="IsValidUser" ></app-side-nav>
    </div>
      <ng-container *ngIf="showlanding;else pageTemplate">
        <div class="main-content-landing">
          <div class="content" [style.margin]="'0px'">
            <div [ngClass]="{'light-grey-border': !showlanding, component: true }">
             
              <router-outlet *ngIf="!isIframe"></router-outlet>
              <app-toaster></app-toaster>
            </div>

          </div>
        </div>
      </ng-container>
      <ng-template #pageTemplate>
       
        <div class="main-content" [ngClass]="{'sidebar-min-content': !expandSideNav,'sidebar-full-content': expandSideNav }">
          <div class="content">
            <div [ngClass]="{'light-grey-border': !showlanding, component: true }">
            
               <!-- <router-outlet></router-outlet> -->
              <router-outlet *ngIf="!isIframe"></router-outlet>
              <app-toaster></app-toaster>
            </div>
            
          </div>
        </div>
      </ng-template>
  </div>
  <div  class="app-body" fxFlex="100%" fxLayout="row" *ngIf="!loginDisplay">
    <div fxLayout="row" fxLayoutAlign="start start"class="login-container">
      <div fxFlex="40" class="left-container">
          <div fxLayout="column" fxLayoutAlign="start start" class="row-section">
              <span class="red-border-top"></span>
              <div class="header-title">Welcome to ABB Aquamaster+</div>
          </div>
          <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="icon-container">
              <img src="../../../assets/images/single-neutral-circle.svg" /><span class="login-txt"> Login</span>
          </div> -->
          <button mat-button class="button-pill color-primary login-btn" (click)="login()">Login</button>
      </div>
      <div fxFlex="60" class="right-container"></div>
  </div>
  </div>
  <!-- <div class="app-body" fxFlex="100%" fxLayout="row" *ngIf="!showLogin">
    <app-login  (loginEvent)="login()"></app-login>
  </div> -->
</div>

  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <!-- <router-outlet *ngIf="!isIframe"></router-outlet> -->
  <app-loader></app-loader>