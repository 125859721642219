import { NgModule } from '@angular/core';
import { LoaderComponent } from '../../modules/loader/loader.component';
import { SharedModule } from '../../shared/shared/shared.module';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    LoaderComponent
  ]
})
export class LoaderModule { }
