import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilitySvc } from './UtilitySvc';
import { SessionStorageService } from './session-storage.service';
import { FormBuilder, FormGroup } from '@angular/forms';

const httpHeadersValue = {
  headers: new HttpHeaders({

  })
}
httpHeadersValue.headers = new HttpHeaders().append('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  url:string = 'assets/mockData.json'
  constructor(private httpClient: HttpClient, private utilitySvc: UtilitySvc, private _storageService: SessionStorageService, private formbuilder:FormBuilder) { }

  getHierarchyforDeviceComparison(){
    // return this.httpClient.get(this.url);
    var headers = this.getHeaders();
    return this.httpClient.get<any>(this.utilitySvc.ComaprisonheirarchyMenu, {headers});
  }

  getHierarchyTreeNode(isShowDevice:boolean){
    //return this.httpClient.get(this.url);
   var headers = this.getHeaders();
   return this.httpClient.get<any>(this.utilitySvc.heirarchyMenu+'?isShowDevice='+isShowDevice, {headers});
 }
  getTenantInfoDetails(useremail: string){
    var headers = this.getHeaders();
    return this.httpClient.get<any>(this.utilitySvc.getTenantInfo+'?useremail='+useremail, {headers});
  }
  getTenantInfo(){
    var headers = this.getHeaders();
    return this.httpClient.get<any>(this.utilitySvc.getTenantInfo, {headers});
  }
  getUserToken(){
    let accessToken = this._storageService.getSession('auth_token');
    const headers =new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    return this.httpClient.get<any>(this.utilitySvc.getUserToken, { headers });
  }

  getHeaders() : HttpHeaders {
    const headers =new HttpHeaders({
      'Authorization': 'Bearer ' + this._storageService.getSession('auth_token'),
      'Access_token' : this._storageService.getSession('access_token')
    })

    return headers;
  }

  getDefaultTimeRange() :  FormGroup{
    return this.formbuilder.group({
      start: [this.formatDate(this.getstartDate())],
      end: [this.formatDate(this.getEndDate())],
    });
  }

  getstartDate(): Date{
    let today= new Date();
    today.setHours(0,0,0);
    return new Date(today.setDate(today.getDate() - 7));
  }

  getEndDate(): Date{
    //console.log("end date :"+ new Date().toDateString())
    return new Date();
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // getMonth() is 0-based
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  formatISODateTimeFormat(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // getMonth() is 0-based
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  formatISODateFormat(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // getMonth() is 0-based
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  storeDataWithExpiry(data: any, ttl: number): void {
    const expiry = new Date().getTime() + ttl;  // ttl is time in ms
    const dataWithExpiry = {
      data: data,
      expiry: expiry
    };
    localStorage.setItem('MenuInfo', JSON.stringify(dataWithExpiry));
  }
  
  getDataWithExpiry(): any {
    const cachedData = localStorage.getItem('MenuInfo');
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      if (new Date().getTime() > parsedData.expiry) {
        // Data has expired, so clear it
        localStorage.removeItem('MenuInfo');
        return null;
      }
      return parsedData.data;
    }
    return null;
  }
  
}
