<div *ngIf="toasts?.length > 0">
    <div *ngFor="let toast of toasts"  class="toast">
      <div class="success" *ngIf="toast.type == 'success'">
        <div class="body-text" *ngIf="toast.display">
          <span class="icon_abb_dias icon-abb_tick icon"></span>
          <span class="content">{{ toast.message }}</span>
          <span class="icon-close" (click)="removeToast(toast)"></span>
        </div>
      </div>

      <div class="warning" *ngIf="toast.type == 'warning'">
        <div class="body-text">
          <span class="icon_abb_dias icon-abb_warning icon"></span>
          <span class="content">{{ toast.message }}</span>
          <span class="icon-close" (click)="removeToast(toast)"></span>
        </div>
      </div>

      <div class="error" *ngIf="toast.type == 'error'">
        <div class="body-text">
          <span class="icon_abb_dias icon-abb_tick icon"></span>
          <span class="content">{{ toast.message }}</span>
          <span class="icon-close" (click)="removeToast(toast)"></span>
        </div>
      </div>

      <div class="primary" *ngIf="toast.type == 'info'">
        <div class="body-text">
          <span class="icon_abb_dias icon-abb_information-circle1 icon"></span>
          <span class="content">{{ toast.message }}</span>
          <span class="icon-close"(click)="removeToast(toast)"></span>
        </div>
      </div>

    </div>
  </div>
  