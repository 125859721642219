import { Component, Inject, OnInit } from '@angular/core';
import { OverlayContainer } from "@angular/cdk/overlay";
import { filter, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { Subject } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

import { CommonService } from './services/common.service';
import { SessionStorageService } from './services/session-storage.service';
import { JwtDecodeService } from './services/jwt.decode.service';
import { LoaderService } from './services/loader.service';
import { LicenseService } from './services/license.service';
import { MatDialog } from '@angular/material/dialog';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { AuthService } from './services/auth.service';
import { ToasterService } from './services/toaster.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  title = 'aquamaster-plus';
  isDarkTheme = false;
  routerStateName: string;
  startYear = 2020;
  expandSideNav = false;
  showlanding = true;
  emailVal = "abcin.abb.com";
  showTemplate = false;
  isIframe = false;
  loginDisplay = false;
  name: string = '';
  userName: string = '';
  shortName: string = '';
  IsValidUser: boolean = false;
  showPopup: boolean = false;
  userRole : string ='';
  sidebarStates = {
    sidebar1: false,
    sidebar2: false,
    sidebar3: false,
    sidebar4: false,
    sidebar5: false,
    sidebar6: false,

  };

  private readonly _destroying$ = new Subject<void>();
  
  constructor( private _overlayContainer: OverlayContainer,private dialog: MatDialog,private toasterService: ToasterService, private authservice:AuthService, private _router: Router,private loaderService: LoaderService,private licenseService: LicenseService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private commonService: CommonService, private _sessionStorageService:SessionStorageService,  private jwtService: JwtDecodeService){

  }
  returnToLanding(){
    this._router.navigate(['/landing'])
  }

   // Options related to Search Bar
   searchBarVisible: boolean[] = new Array(3).fill(false);
   searchActive: boolean = false;
   show_cross: boolean = false;
   searchQueries: string[] = new Array(3).fill("");
 
 
   clearSearch(index) {
     this.searchQueries[index] = "";
   }
 
   // For Open and Close Hamburger
   expandCollapse(id: string) {
    this.sidebarStates[id] = !this.sidebarStates[id];
  }

  showLogin = false;
  ngOnInit(): void {
    this.IsValidUser = false;
    const cookie = this.checkCookieConsent();
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loaderService.visible(true);
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loaderService.visible(false);
      }
    });

    if(!cookie){
      this.dialog.open(CookieConsentComponent,  {disableClose: true,autoFocus:false});
    }
    else{
    this.updateDarkTheme();  

    //AZURE Code 
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
            if (this.authService.instance.getAllAccounts().length === 0) {
                window.location.pathname = "/";
            } else {
                this.setLoginDisplay();
            }
        });

    this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this._destroying$)
        )
        .subscribe(() => {
            this.setLoginDisplay();
            this.checkAndSetActiveAccount(); 
        }) 
      }
  }

  loadLandingPage(){
    this._router.events
    .pipe(filter((routerEvent) => routerEvent instanceof NavigationEnd))
    .subscribe((routerEvent:any) =>  {
      this.routerStateName = routerEvent.url.split('/')[1];
      if (
        routerEvent.urlAfterRedirects == '/landing' ||
        routerEvent.urlAfterRedirects == '/practice' ||
        routerEvent.urlAfterRedirects == '/apps-asset' ||
        routerEvent.urlAfterRedirects == '/' 
      ) {
        this.showlanding = true;
        this.expandSideNav = false;
        this.showTemplate =false;
      } 
      else if ( routerEvent.urlAfterRedirects == '/login'){
        this.showlanding = false;
        this.expandSideNav = false;
        this.showLogin = true;
        this.showTemplate = false;
      }
      else {
        this.showlanding = false;
        this.showLogin = false;
        this.showTemplate =true;
      }
    });
  }
  
  onexpandSideNav(event: boolean) {
    this.expandSideNav = event;
  }

  updateDarkTheme(){
    if (this.isDarkTheme) { 
      this._overlayContainer.getContainerElement().classList.add('dark-theme');
      this._overlayContainer.getContainerElement().classList.remove('light-theme');
      document.getElementsByTagName('html')[0].classList.add('dark-theme');
      document.getElementsByTagName('html')[0].classList.remove('light-theme');      
    }
    else {    
      this._overlayContainer.getContainerElement().classList.remove('dark-theme');
      this._overlayContainer.getContainerElement().classList.add('light-theme');
      document.getElementsByTagName('html')[0].classList.remove('dark-theme');
      document.getElementsByTagName('html')[0].classList.add('light-theme');
    }
  }

  onValChange(event:any){
    if(event.value == 'light'){
      this.isDarkTheme = false;
    }
    else{
      this.isDarkTheme = true;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay){

      this.setProfileDetails();
    }    
  }

  setProfileDetails() {
    this.initialize();

    this.name = this.authService.instance.getAllAccounts()[0].name;
    this.userName = this.authService.instance.getAllAccounts()[0].username;
    //this.getTenantId(this.userName);
    var sName = this.name.split('');
    this.shortName = (Array.from(sName[0])[0] + Array.from(sName[1])[0]).toUpperCase();
  }

  getTenantId(email:any){
    //email = 'prakash.tiwari@in.abb.com'
    // this.commonService.getTenantInfoDetails(email).subscribe((res:any) => {
    //   let tenantId = res.tenantId;
    //   this._sessionStorageService.setSession('tenant_id', tenantId);
    // })
  }

checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        // add your code for handling multiple accounts here
        this.authService.instance.setActiveAccount(accounts[0]);
        this.setProfileDetails();
    }
}

checkCookieConsent() : boolean {
  const consent = this.getCookie('ABB-CookieConsent');
  if (consent === 'true') {
    this.showPopup = false;
  }
  return this.getBoolean(consent);
}

getCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

getBoolean(value: any) : boolean{
  switch(value){
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
          return true;
      default: 
          return false;
  }
}

async initialize(){
  await this.authService.instance.initialize();

  const accessTokenRequest = {
    //scopes:["profile openid email User.Read.All"],
    scopes: ["openid offline_access api://c9a1602d-5646-4fe2-adb2-6d1bdcc9c86b/Read"],
    account: this.authService.instance.getAllAccounts()[0],
  };    
 
  this.authService.instance.acquireTokenSilent(accessTokenRequest).then( (accessTokenReponse) => {
    if(accessTokenReponse != null) {
      let accessToken = accessTokenReponse.accessToken; 
      this._sessionStorageService.setSession('IsTokenGenerated', 'false');
      this._sessionStorageService.setSession('auth_token', accessToken);
      this.loaderService.visible(true);
      this._sessionStorageService.setSession('IsValidUser', 'false')
      this.commonService.getUserToken().subscribe((token:any) => { 
        this._sessionStorageService.setSession('access_token', token.Access_token);
        var decodeToken= this.jwtService.decodeToken(token.Access_token);
        this._sessionStorageService.setSession('IsTokenGenerated', 'true');
        this._sessionStorageService.setSession('user_data', JSON.parse(decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']))
        this.userRole= this._sessionStorageService.getSession('user_data')['RoleNames'][0];
        if(this._sessionStorageService.getSession('user_data').RoleNames.includes('Supervisor') || this._sessionStorageService.getSession('user_data').RoleNames.includes('Operator')){
          this._sessionStorageService.setSession('IsValidUser', 'true')
          this.loadLandingPage();
          this._router.navigate(['/landing'])
          this.IsValidUser = true;
          this.loaderService.visible(false);           
            this.getLicenseInfoByTenantId(this._sessionStorageService.getSession('user_data').TenantId);
            // this.commonService.getHierarchyTreeNode(true).subscribe((response: any) => {
            //   this._sessionStorageService.setSession('MenuInfo', response);
            // });
            this.commonService.getTenantInfo().subscribe((res:any) => {
              this._sessionStorageService.setSession('TenantInfo', res);
            });
        }else{    
           this.loaderService.visible(false); 
           this._router.navigate(['/landing']);
            this.toasterService.show('You are not permitted to use this application', 'error'); 
        }
      }, err=>{
         this.loaderService.visible(false);
         this._router.navigate(['/landing']);
      });
    }
  });
}


login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
    } else {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }
}

logout() {
    const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];
    this._sessionStorageService.clear();
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.authService.logoutPopup({
            account: activeAccount,
        });
    } else {
        this.authService.logoutRedirect({
            account: activeAccount,
        });
    }
}

getLicenseInfoByTenantId(tenantId: string): void {
  this.loaderService.visible(true);
  this.licenseService.getLicenseInfo(tenantId).subscribe ({ next: (res) => {
    this._sessionStorageService.setSession('IsLicenseActivated', res.IsActivated ? 'true': 'false');
    this._sessionStorageService.setSession('IsLicenseExperied', res.IsExpired ? 'true': 'false');
    if(res.IsActivated){
    var licenseInfo = this.licenseService.objectConvertion(res);  
    this._sessionStorageService.setSession('LicenseInfo', JSON.stringify(licenseInfo));         
    }
    this.loaderService.visible(false);    
    }, error: (err) => {
      this.loaderService.visible(false);
    } 
  });
}
}
