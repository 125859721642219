<div class="modal-dialog sweet-alert-modal new-modal-dialog">
    <div  fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <div class="red-border-top"></div>
        <div class="title-txt-24">Cookie Settings</div>
      </div>
   
      <!-- <div class="icon-close" (click)="cancelClicked()"></div> -->
    </div>

    <div mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="start start" class="modal-container">
         <p class="msg-txt" style="margin-top: 1rem !important;">Our application uses cookies or other similar technologies which are necessary for running the application and for providing the services you request. <br/>For more information, please read our <a href="https://new.abb.com/privacy-policy" target="_blank">Privacy and Cookie Notice | ABB</a>.</p>
      </div>
    </div>
    <div mat-dialog-actions>
      <div fxLayout="row" class="footer-content" fxLayoutAlign="end center" fxLayoutGap="8px">
        <!-- <button class="button-pill discreet" mat-button  i18n (click)="acceptCookies()">Accept Selected</button> -->
        <button class="button-pill color-red-button" mat-button  i18n (click)="acceptCookies()">Accept </button>
      </div>
    </div>
  </div>