import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-token-error',
  templateUrl: 'token-error.component.html',
  styleUrls: ['token-error.component.scss']
})
export class TokenErrorComponent implements OnInit {
  constructor(private msalService: MsalService) { }

  ngOnInit(): void {
   
  }

  logOut(){
    this.msalService.instance.logoutRedirect();
  }
}
