import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { SessionStorageService } from '../../services/session-storage.service';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {

  menus:any;
  title:any;
  icon:any;
  description:any;
  icondescription:any;
  date: Date = new Date();
  startYear: Number = 2020;
  loginDisplay = false;
  
  //"Description  will appear here, Description  will appear hereDescription  will appear here, Description  will appear here, Description  will appear here, Description  will appear here"
  landingPageJson = {
    "appName": "AquaMaster+",
    "appDescription": "",
    "infographicsIcon": "assets/images/abb_aquamaster4.svg",
    "infographicsDescription": "",
    "menus": [
      {
        "title": "Title 1",
        "subMenus": [
          {
            "tileTitle": "Area Overview",
            "url": "/area-overview",
            "tileIcon": "assets/images/abb-area overview.svg"
          },
          {
            "tileTitle": "Device Insight",
            "url": "/device-insight",
            "tileIcon": "assets/images/abb-device.svg"
          },
          {
            "tileTitle": "Event Log",
            "url": "/event-log",
            "tileIcon": "assets/images/abb-event-log.svg"
          },
          {
            "tileTitle": "Device Comparision",
            "url": "/tag-comparision",
            "tileIcon": "assets/images/abb-onboarding.svg"
          } 
        ]
      },
 
  
    ]
  }
  
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private router: Router, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private _sessionStorageService: SessionStorageService) { }
    ngOnInit() 
    { 
      this.menus = this.landingPageJson.menus;
      this.title = this.landingPageJson.appName;
      this.icon = this.landingPageJson.infographicsIcon;
      this.description = this.landingPageJson.appDescription;
      this.icondescription = this.landingPageJson.infographicsDescription;

      this.msalBroadcastService.msalSubject$
      .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
      });

  this.msalBroadcastService.inProgress$
      .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
          this.setLoginDisplay();
      })
    }
  

  setLoginDisplay() {
    const validUser = this._sessionStorageService.getSession('IsValidUser') == 'true' ? true : false;    
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0 && validUser;
    // this._sessionStorageService.setSession('tenantId','abcd');
    // this._sessionStorageService.setSession('deviceId', '7e43cedb-1903-49cf-bd8e-08d7dffb03a8');
    //this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  navigateToOverview(item:any){
    this.router.navigateByUrl(item);
  }
}

