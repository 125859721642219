import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Edg") > -1;
export const msalConfig: Configuration = {
    auth: {
        clientId: '01acb86c-d22f-49e6-802f-5bce0494a10d',
        authority: 'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        clientCapabilities: ['CP1']
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, 
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                // console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const protectedResources = {
    apiGateway: {
        endpoint: "https://dev.api.aquamasterplus.measurementservice.abb.com/api/AquaMasterGateway",
        scopes: {
            read: ["api://c9a1602d-5646-4fe2-adb2-6d1bdcc9c86b/Read"],
            write: ["api://c9a1602d-5646-4fe2-adb2-6d1bdcc9c86b/Write"]
        }
    }
}

export const loginRequest = {
    scopes: [] as any[]
};
