import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { HeaderModule } from './components/header/header.module';
import { TranslocoService } from '@ngneat/transloco';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SharedModule } from './shared/shared/shared.module';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig, protectedResources } from './app.azure.config';
import { LandingComponent } from './modules/landing/landing.component';
import { CommonModule } from '@angular/common';
import { ToasterModule } from './components/toaster/toaster.module';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  protectedResourceMap.set(protectedResources.apiGateway.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiGateway.scopes.read],
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiGateway.scopes.write],
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiGateway.scopes.write],
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiGateway.scopes.write],
    },
  ]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}
import { LoginComponent } from './components/login/login.component';
import { ToasterService } from './services/toaster.service';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { TokenErrorComponent } from './components/error/token-error.component';
import { LoaderModule } from './modules/loader/loader.module';
import { ArcDateTimeRangePickerComponent } from './shared/components/arc-date-time-range-picker/arc-date-time-range-picker.component';
import { CustomErrorHandler } from './custom.error.Handler';

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    LoginComponent,
    LandingComponent,
    CookieConsentComponent,
    UnauthorizedComponent,
    TokenErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HeaderModule,
    TranslocoRootModule,
    //NoopAnimationsModule,
    MaterialModule,
    NgApexchartsModule,
    SharedModule,
    MsalModule,
    CommonModule,
    ToasterModule,
    LoaderModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpConfigInterceptor, 
      multi: true 
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    TranslocoService,
    ToasterService   
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
