import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { UtilitySvc } from './UtilitySvc';
import { LicensCardDetails, LicenseInfo, LicenseList } from '../interfaces/license.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
    
   public licenseInfoDetails: LicensCardDetails;
   private existingLicenseInfo = new BehaviorSubject<LicenseInfo>({} as any);
   existingLicense$ = this.existingLicenseInfo.asObservable();

   private historyDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
   public historyData$: Observable<any> = this.historyDataSubject.asObservable();

   private dateRangeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
   public dateRange$: Observable<any> = this.dateRangeSubject.asObservable();

   constructor(protected httpClient: HttpClient, private _commonService : CommonService, private utilitySvc: UtilitySvc) { }

    getLicenseInfo(tenantId: string) {
        var headers = this._commonService.getHeaders();
        return this.httpClient.get<LicenseList>(this.utilitySvc.getLicenseInfo, {headers});
    }
    
    private updateHistoryData(data: any) {
        //console.log(data);
        this.historyDataSubject.next(data);
    }

    private updatedateRange(data: any) {
        //console.log(data);
        this.dateRangeSubject.next(data);
    }

    private addMonths(date, months) {
        date.setMonth(date.getMonth() + months);
        return date;      
    }

    private calulateLeastAccessibleDate(HistoryInMonths: number) : Date {
        let futureDate = this.addMonths(new Date(), -HistoryInMonths); 
        return new Date(futureDate);
    }

    private getDatePastDays(month: number): Date {
        const today = new Date();  // Get today's date
        today.setDate(today.getDate() - (30 * month));  // Add 90 days to today's date
        return today;
    }

    public objectConvertion(licenceInfoDetails : any) {
        let licenceInfo: LicenseInfo = {
            isExpired : licenceInfoDetails.IsExpired,
            LicenseType : licenceInfoDetails.ProoductType,
            isActivated: licenceInfoDetails.IsActivated,
            ExpiryDate: licenceInfoDetails.ExpirationDate,

            isWarningPeriod: licenceInfoDetails.featureList['AmPlus.AdminUser']?.DaysRemaining <= 15 ? true : false,
            WarningDays : licenceInfoDetails.featureList['AmPlus.AdminUser']?.DaysRemaining,

            LeastAccessibileDate: this.calulateLeastAccessibleDate( licenceInfoDetails.featureList['AmPlus.HistoryData']?.TotalCount),

            AdminUserCount: licenceInfoDetails.featureList['AmPlus.AdminUser']?.AvailableCount,
            TotalAdminUserCount: licenceInfoDetails.featureList['AmPlus.AdminUser']?.TotalCount,

            IsCloudHostingAvailable: licenceInfoDetails.featureList['AmPlus.CloudHosting']?.IsAvailable,
            

            DataPointCount: licenceInfoDetails.featureList['AmPlus.DataPoints']?.AvailableCount,
            TotalDataPointCount: licenceInfoDetails.featureList['AmPlus.DataPoints']?.TotalCount,
            
            //AmPlus.DataStorage, Types needed  

            DataStorageDuration: licenceInfoDetails.featureList['AmPlus.DataStorageDuration']?.AvailableCount,
            TotalDataStorageDuration: licenceInfoDetails.featureList['AmPlus.DataStorageDuration']?.TotalCount,
            
            DataBuffer: licenceInfoDetails.featureList['AmPlus.HistoryData']?.TotalCount,

            UserCount: licenceInfoDetails.featureList['AmPlus.User']?.AvailableCount,
            TotalUserCount: licenceInfoDetails.featureList['AmPlus.User']?.TotalCount,

    
        };
        this.existingLicenseInfo.next(licenceInfo);
        this.licenseInfoDetails = this.licenseCardDetails(licenceInfoDetails)
        return licenceInfo;
      }

      public licenseCardDetails(licenceInfoDetails: any){

        let DataStorageType : string = '';
        if(licenceInfoDetails.featureList['AmPlus.CloudHosting']?.IsAvailable){
            DataStorageType = "Cloud";
        }
        if(licenceInfoDetails.featureList['AmPlus.OnPremHosting']?.IsAvailable){
            DataStorageType = "On-premise";
        }
        if(licenceInfoDetails.featureList['AmPlus.OfflineHosting']?.IsAvailable){
            DataStorageType = "Offline";
        }
     
        let AdminUserCount: string = '';
        if(licenceInfoDetails.featureList['AmPlus.AdminUser']?.IsAvailable){
            AdminUserCount = licenceInfoDetails.featureList['AmPlus.AdminUser']?.AvailableCount + " (Active) /  " + licenceInfoDetails.featureList['AmPlus.AdminUser']?.TotalCount + " (max)";
        }

        let UserCount: string = '';
        if(licenceInfoDetails.featureList['AmPlus.User']?.IsAvailable){
            UserCount = licenceInfoDetails.featureList['AmPlus.User']?.AvailableCount + " (Active) /  " + licenceInfoDetails.featureList['AmPlus.User']?.TotalCount + " (max)";
        }
     
        let DataBuffer : string = '';
        if(licenceInfoDetails.featureList['AmPlus.HistoryData']?.IsAvailable){
            DataBuffer = licenceInfoDetails.featureList['AmPlus.HistoryData']?.AvailableCount + " month(s)";
            this.updatedateRange({ days: parseInt(licenceInfoDetails.featureList['AmPlus.HistoryData']?.AvailableCount) * 30 });
            //this.updateHistoryData({ mindate: this.getDatePastDays(parseInt(licenceInfoDetails.featureList['AmPlus.HistoryData']?.AvailableCount)),  maxdate: new Date()});
        }
     
        let DataStorageDuration: string='';
        if(licenceInfoDetails.featureList['AmPlus.DataStorageDuration']?.IsAvailable){
            DataStorageDuration = licenceInfoDetails.featureList['AmPlus.DataStorageDuration']?.TotalCount + " years";
            this.updateHistoryData({ mindate: this.getDatePastDays(parseInt(licenceInfoDetails.featureList['AmPlus.DataStorageDuration']?.AvailableCount) * 12),  maxdate: new Date()});
            //licenceInfoDetails.featureList['SmartMaster.Users'].AvailableCount + " out of " + 
        }
     
        let DataPoints: any = [];
        if(licenceInfoDetails.featureList['AmPlus.DataPoints']?.IsAvailable){
            DataPoints = licenceInfoDetails.featureList['AmPlus.DataPoints']?.AvailableCount +' / '+ licenceInfoDetails.featureList['AmPlus.DataPoints']?.TotalCount;
        }
        
        let details: LicensCardDetails = {
          DataStorageType : DataStorageType,
          AdminUserDescription: AdminUserCount,          
          UserDescription: UserCount,
          DataBuffer: DataBuffer,
          DataStorageDuration: DataStorageDuration,
          DataPoints: DataPoints,
          ExpirationDate: licenceInfoDetails.ExpirationDate,
          LicenseType: licenceInfoDetails.ProoductType,
          CurrentAdminUserCount: licenceInfoDetails.featureList['AmPlus.AdminUser']?.AvailableCount,
          TotalAdminUserCount: licenceInfoDetails.featureList['AmPlus.AdminUser']?.TotalCount,
          CurrentUserCount: licenceInfoDetails.featureList['AmPlus.User']?.AvailableCount,
          TotalUserCount: licenceInfoDetails.featureList['AmPlus.User']?.TotalCount,
          IsActivated: licenceInfoDetails.IsActivated,
          IsExpired: licenceInfoDetails.IsExpired          
        }
     
        return details;
      }
}