import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { finalize, Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import { Router } from '@angular/router';
import { authmode } from '../app.authmode.config';
import { LoaderService } from '../services/loader.service';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    public authmode: any = authmode;
    constructor(
        private _storageService: SessionStorageService,
        private router: Router, private loaderService: LoaderService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
      this.loaderService.visible(true);
      return next.handle(req).pipe(
        finalize(() => this.loaderService.visible(false))
      );
    }
    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     // if (authmode.IsAzureAdAuth && !request.url.includes("https://login.microsoftonline.com/") && this._storageService.getSession('tenant_id') != null) {

    //     //     let authId = this._storageService.getSession('access_token');
           
    //     //     request = request.clone({ headers: request.headers.append('tenantId', this._storageService.getSession('tenant_id')) });

    //     //     return next.handle(request).pipe(
    //     //         map((event: HttpEvent<any>) => {
    //     //             if (event instanceof HttpResponse) {
    //     //             }
    //     //             return event;
    //     //         }));
    //     // }
    //     return next.handle(request);
    // }
}