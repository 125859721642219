import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() expandSideNav = new EventEmitter<boolean>();
  @Input() sidebarStates: any;
  @Input() hierarchy: boolean;
  @Input() sidebarId: string;
  @Input() subMenuWithIcon: boolean;
  @Input() overflowSubmenu: boolean;
  expandSubMenu: boolean = false;
  selectedSubMenu: string = "";
  selectedMenu: string = "Module 1";
  expandSideNavFlag = false;
  routerStateName: string; 
  constructor(private router: Router) {
  }

  ngOnInit() {
    let url = this.router.url.replace('/','');
    for(let index = 0; index < this.sidebarItems.length; index++){
      if(this.sidebarItems[index].navigate === url) 
      {
        this.selectItem(this.sidebarItems[index]);
        this.selectedIndex = index;
      }
    }
  }

  selectSubMenu(event, nav, menu: string) {
    event.stopPropagation();
    this.selectedSubMenu = menu;
    this.selectedMenu = nav.text;
  }

  isMenuActive(menuItem) {
    if (menuItem.subMenu && this.selectedSubMenu === "") {
      return false;
    } else return this.selectedMenu === menuItem.text;
  }

  selectItem(nav: any) {
    if (nav.disabled) return;
    if (nav.subMenu && !this.overflowSubmenu) {
      this.expandSubMenu = !this.expandSubMenu;
    } else {
      this.selectedMenu = nav.text;
      this.selectedSubMenu = "";
    }
  }
  menuicon = {
    navigate: 'expand',
    icon: 'icon-abb_right_16 icon_abb_16',
    alternateIcon: 'icon-abb_left_16 icon_abb_16',
    text: '',
  };
  selectedIndex = 0;
  sidebarItems = [
    {
      navigate: 'area-overview',
      icon: 'icon-abb_dashboard icon_abb_dias nav-icon',
      text: 'Area Overview',
      selected: false,
      subMenu: false,
      disabled: false
    },
    {

      navigate: 'device-insight',
      icon: 'icon-abb_device-measure icon_abb_dias nav-icon',
      text: 'Device Insight',
      selected: false,
      subMenu: false,
      disabled: false
    },
    {
      navigate: 'event-log',
      icon: 'icon-abb-event-log icon_abb_dias nav-icon',
      text: 'Event logs',
      selected: false,
      subMenu: false,
      disabled: false
    },
    {

      navigate: 'tag-comparision',
      icon: 'icon-abb_onboarding1 icon_abb_dias nav-icon',
      text: 'Device Comparision',
      selected: false,
      subMenu: false,
      disabled: false
    }
  ];
 
  expand(menuicon:any) {
    if (menuicon === 'expand') {
      this.expandSideNavFlag = !this.expandSideNavFlag;
      this.expandSideNav.emit(this.expandSideNavFlag);
    }
  }

  navigate(navigate:any,index:any) { 
    this.selectedIndex = index;
    if (typeof navigate === typeof '') {
      this.router.navigateByUrl(navigate);
    } else if (typeof navigate === typeof {}) {
    }
  }

  isActive(instruction: any[]): boolean {
    return this.router.isActive(this.router.createUrlTree(instruction), true);
  }
}
