
    <section class="landing-ui-container">
        <div fxLayout="row" fxLayoutAlign="start start" class="ht-100">
          <div fxFlex="100" class="left-container">
            <div fxLayout="column" fxLayoutAlign="start start" class="row-section">
            <!-- <span class="red-border-top"></span> -->
            <div class="header-title row-section" style="color: red;"> Access Denied !
            </div>
              <p style="color: red;"> Not Authorized to use this Application</p>
              <p>
                <button mat-button class="button-pill color-primary login-btn" (click)="logOut()" style="color: whitesmoke;">Logout</button>
                <!-- <button mat-button class="button-pill color-primary button-with-icon"  (click)="logOut()">
                    <div class="button-text" style="color: white;">Logout</div>
                  </button> -->
              </p>
            </div>           
          </div>
         
        </div>
      </section>