  <mat-sidenav-container class="side-nav-container">
    <mat-sidenav #sidenav opened fixedTopGap="50" mode="side">
      <ul>
    
        <li
          *ngIf="hierarchy"
          class="hierarchy"
          fxLayout="row"
          matTooltipPosition="after"
          [matTooltip]="sidebarStates[sidebarId] ? '' : 'Hierarchy'">
          <span class="icon-abb_hierarchy1 icon_abb_dias nav-icon"></span>
          <span
            *ngIf="sidebarStates[sidebarId]"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="icon-label"
            >Hierarchy</span
          >
        </li>
        <li
          *ngFor="let nav of sidebarItems"
          routerLink="{{ nav.navigate }}"
          [matTooltip]="sidebarStates[sidebarId] ? '' : nav.text"
          [matMenuTriggerFor]="nav.subMenu && !sidebarStates[sidebarId] ? navMenu : null"
          [ngClass]="{
            selected: isMenuActive(nav) || (!this.sidebarStates[sidebarId] && nav.subMenu && this.selectedSubMenu !== ''),
            'menu-without-submenu': (isMenuActive(nav) && (overflowSubmenu || !nav.subMenu)) || (!this.sidebarStates[sidebarId] && nav.subMenu && this.selectedSubMenu !== ''),
            disabled: nav.disabled,'white-bg': sidebarStates[sidebarId] && expandSubMenu && !isMenuActive(nav) && nav.subMenu
          }"
          matTooltipPosition="after"
          matTooltip="View More">
          <div
            fxLayout="row"
            class="selected"
            [ngClass]="{ disabled: nav.disabled }"
            [fxLayoutAlign]="sidebarStates[sidebarId] && nav.text ? 'start center' : 'start center'"
            (click)="selectItem(nav)">
            <span [ngClass]="{ disabled: nav.disabled }" [class]="nav.icon"
              >
          
          </span>
            <div
              *ngIf="sidebarStates[sidebarId] && nav.text"
              class="icon-label"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              [routerLinkActive]="nav.text === 'Home' ? '' : 'selected'">
              {{ nav.text }}
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8">
              
              </div>
            </div>
          </div>
        
  
          <mat-menu #navMenu="matMenu" yPosition="below" xPosition="after" class="sub-nav-menu">
            <div
              class="list-menu"
              fxLayout="row"
              fxLayoutGap="8"
              [ngClass]="{ selected: selectedSubMenu === 'SubMenu1' }"
              mat-menu-item
              (click)="selectSubMenu($event, nav, 'SubMenu1')">
              <span
                *ngIf="subMenuWithIcon"
                class="icon-abb_setting icon_abb_dias sub-menu-icon"></span
              ><span class="name-txt">Sub Menu 1</span>
            </div>
            <div
              class="list-menu"
              fxLayout="row"
              fxLayoutGap="8"
              [ngClass]="{ selected: selectedSubMenu === 'SubMenu2' }"
              mat-menu-item
              (click)="selectSubMenu($event, nav, 'SubMenu2')">
              <span
                *ngIf="subMenuWithIcon"
                class="icon-abb_setting icon_abb_dias sub-menu-icon"></span
              ><span class="name-txt">Sub Menu 2</span>
            </div>
            <div
              class="list-menu"
              fxLayout="row"
              fxLayoutGap="8"
              [ngClass]="{ selected: selectedSubMenu === 'SubMenu3' }"
              mat-menu-item
              (click)="selectSubMenu($event, nav, 'SubMenu3')">
              <span
                *ngIf="subMenuWithIcon"
                class="icon-abb_setting icon_abb_dias sub-menu-icon"></span
              ><span class="name-txt">Sub Menu 3</span>
            </div>
          </mat-menu>
        </li>
      </ul>
    </mat-sidenav>
  </mat-sidenav-container>
  