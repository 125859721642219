import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: 'cookie-consent.component.html',
  //styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit {
  showPopup: boolean = true;

  constructor(private dialogRef: MatDialogRef<CookieConsentComponent>) { }

  ngOnInit(): void {
    this.checkCookieConsent();
  }

  cancelClicked(): void {
    this.dialogRef.close();
  }

  checkCookieConsent() {
    const consent = this.getCookie('ABB-CookieConsent');
    if (consent === 'true') {
      this.showPopup = false;
    }
  }

  acceptCookies() {
    this.setCookie('ABB-CookieConsent', 'true', 365);
    this.cancelClicked();
  }

  setCookie(name: string, value: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  getCookie(name: string) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
